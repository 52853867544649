import * as React from "react";
import Helmet from "react-helmet";

import { helmet } from "../../utils/helmet";
import { Header } from "../header/Header";
import { Devtools } from "../devtools/Devtools";
import { isDev } from "../../utils/common";
import s from "./AppLayout.scss";
// import { Link as HeaderLink } from "components/header/Link";

interface IAppLayoutProps {
  children: React.ReactNode;
}

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    {!isDev && (
      <>
        <Header>{}</Header>
        {children}
      </>
    )}

    {isDev && (
      <>
        <Header>
          {/* <HeaderLink name="Resume" to="/resume" /> */}
          {/* <HeaderLink name="functies" to="/about" />
          
          <HeaderLink name="contact" to="/about" /> */}
          {/* <Button target="_self" type="nofill" to="https://app.wemble.io/chat">
            Login
          </Button> */}
        </Header>
        {/* <HeaderLink
        name="github"
        to="https://github.com/ueno-llc"
        icon={<Github />}
      /> */}
        {children}

        {/* <Footer
          logo={<Logo />}
          social={[
            { icon: <Dribbble />, to: "https://dribbble.com/ueno" },
            { icon: <Twitter />, to: "https://twitter.com/uenodotco" },
            { icon: <Github />, to: "https://github.com/ueno-llc" },
            { icon: <Instagram />, to: "https://www.instagram.com/uenodotco" },
            { icon: <Facebook />, to: "https://www.facebook.com/uenodotco" },
            { icon: <Linkedin />, to: "https://www.linkedin.com/company/ueno" },
          ]}
        /> */}
      </>
    )}

    {isDev && <Devtools />}
  </div>
);
